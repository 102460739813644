export const API_URL = 'https://api.airtable.com';
export const API_BASE = 'app78J4WZPX31CCEB';
export const API_KEY = 'keyrHpbJFFaF4wYSK';
export const WEBCONFIG_HOME_URL = 'https://op-prodconfig.agentur-id.de/';
export const DB_LOGIN_API =
  'https://universal.dasbad3.de/universalbackend/public/api/authentication/webconfig_login';
export const DB_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration_sec/';
export const DOWNLOAD_API =
  'https://universal.dasbad3.de/universalbackend/public/api/bucket/downloadDigicoachImage/digicoachlogo/';
export const UPLOAD_API =
  'https://universal.dasbad3.de/universalbackend/public/api/bucket/uploadDigicoachImage';
export const ZOHO_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/finish/';
export const ZOHO_EDIT_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/editUpdate/';
export const ZOHO_API_SAVE_CATEGORY =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/saveCategory/';
export const ZOHO_EDIT_API_CATEGORY =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/saveCategory/';
export const WEBCONFIG_TRADE_MODE = 'OP';
export const SHOW_WEBSITE_BUY = true;
export const SHOW_PRICE_TABLE = false;
